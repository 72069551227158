import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
const MeetMike = ({ data }) => (
  <Layout>
    <SEO title="Meet Mike" />
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="text-uppercase sr-video text-center">
              <strong>meet mike</strong>
            </h1>
            <hr className="sr-video" />
          </div>
        </div>
        <div className="row flex-row-reverse">
          <div className="col-sm-4 sr-video">
            <Zoom>
              <Img
                className="img-fluid"
                style={{ borderRadius: "6px" }}
                fluid={data.headshot.childImageSharp.fluid}
              />
            </Zoom>
            <hr className="d-block d-sm-none" />
          </div>
          <Fade>
            <div className="col-sm-8 sr-video">
              <p>
                Talk to Mike Byster for 10 minutes and he will change the way
                you think about learning forever. With his contagious enthusiasm
                and passion for math and memory, he will prove that not only are
                you able to expand your mind, but you will have fun doing so.
                With one of the fastest mathematical minds on the planet, Mike
                receives thousands of calls each year from schools requesting
                him to visit and share his knowledge. He has been to over 16,000
                classrooms, nursing homes, and businesses worldwide — all on his
                own dime.
                <br />
                <br />
                ABC's 20/20 in{" "}
                <a href="https://www.youtube.com/watch?v=hv5QLyBr1CQ">
                  an investigative report
                </a>{" "}
                said, "He turns average kids into whiz kids." After Mike was
                featured on 20/20, the program received more emails than any
                other segment in the history of the show.
                <br />
                <br />
                Winner of the title{" "}
                <a href="https://www.youtube.com/watch?v=p_098lHOeDI">
                  "Superhuman"
                </a>{" "}
                June, 2017 on Fox, he has also been featured on{" "}
                <a href="https://www.youtube.com/watch?v=Y7OQGyQUez0">
                  "Windy City Live"
                </a>
                , The Hallmark Channel's{" "}
                <a href="https://www.youtube.com/watch?v=Sv9VLhODfWo">
                  "Home and Family"
                </a>
                ,{" "}
                <a href="https://wgntv.com/2017/08/30/one-of-the-fastest-brains-in-the-world-mike-byster-blows-anchors-away-with-brainetics/">
                  WGN News
                </a>
                ,{" "}
                <a href="https://abcnews.go.com/2020/story?id=2690724&page=1">
                  ABC News
                </a>
                , Reader's Digest, Bloomberg News, NPR,{" "}
                <a href="https://www.chicagotribune.com/news/ct-xpm-2004-04-18-0404180466-story.html">
                  Chicago Tribune
                </a>
                ,{" "}
                <a href="https://chicago.suntimes.com/2017/6/7/18363805/local-brainiac-mike-byster-competes-on-superhuman">
                  Chicago Sun Times
                </a>
                , Atlanta-Journal Constitution, The Gayle King Show, Bulldog in
                the Morning Show, Jenniffer Weigel's{" "}
                <a href="https://www.youtube.com/watch?v=StTBtU0-pV0">
                  "I'm Spiritual, Dammit"
                </a>
                , Paul Lisnek's{" "}
                <a href="https://wgnradio.com/2017/08/17/paul-lisneks-behind-the-curtain-illinois-budget-and-superhuman-math-whiz/">
                  "Behind the Curtain"
                </a>
                , and many local and regional media channels across the country.
                Watching Mike do huge math equations in his head—even faster
                than a calculator—is extraordinary. To see kids do exactly the
                same thing is life changing. But Mike was not born a genius. He
                broke learning skills down into simple easy to follow steps,
                which are not taught in schools. Skills that every student needs
                for success. In doing so, he wants to empower all individuals to
                be able to reach their full potential.
                <br />
                <br />
                Today, Mike's mission in life is to positively impact the minds
                and lives of all students. Even for students with test anxiety
                and learning disabilities, Mike's approach to learning takes the
                pressure off, making the process of learning a positive
                experience. Mike has literally written the book on mental math,
                memory, focus, concentration, and organization skills. His book,
                The Power of Forgetting, and DVD system, Brainetics, were born
                from his love of learning and his own struggles in school. He
                created Brainetics so the entire family can have a great time
                while learning. Brainetics is the winner of the{" "}
                <a href="http://archive.parentschoice.org/product.cfm?product_id=24922">
                  2008 Parent's Choice Award
                </a>
                ,{" "}
                <a href="https://www.prlog.org/11685215-moms-best-award-announces-winners.html">
                  2012 Mom's Best Award
                </a>
                ,{" "}
                <a href="https://www.grandmagazine.com/pdfs/DrToy2012_100BestChildrensProducts.pdf">
                  2012 Dr. Toy's 10 Best Games Award
                </a>
                , and{" "}
                <a href="http://www.academicschoice.com/dvds/brainetics.php">
                  2015 Academics' Choice "Brain Toy" Award
                </a>
                .
                <br />
                <br />A native of Chicago, Mike earned his Business degree at
                the University of Illinois and he is a member of the National
                Council of Mathematics Teachers and Mensa.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  </Layout>
)

export default MeetMike

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    headshot: file(relativePath: { eq: "mikebyster-headshot.jpg" }) {
      ...fluidImage
    }
  }
`
